export const getRandomInt = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const availableColors = [
    '#4A90E2', '#50E3C2', '#F5A623', '#D0021B', '#7ED321',
    '#B8E986', '#9013FE', '#4A4A4A', '#FF7600', '#417505'
];

