import React, { useState, useEffect, useMemo } from 'react';

const availableColors = [
    '#4A90E2', '#50E3C2', '#F5A623', '#D0021B', '#7ED321',
    '#B8E986', '#9013FE', '#4A4A4A', '#FF7600', '#417505'
];

const Book = ({ id, title, link, height, width, tiltAngle, isEnd, tags, icon, created, coverImage }) => {
    const [isTapped, setIsTapped] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    link = link.replace("https://www.notion.so/", "https://www.briannaokeefe.com/")

    useEffect(() => {
        const checkIfMobile = () => {
            setIsMobile('ontouchstart' in window || navigator.maxTouchPoints > 0);
        };

        checkIfMobile();
        window.addEventListener('resize', checkIfMobile);

        return () => window.removeEventListener('resize', checkIfMobile);
    }, []);

    const hashCode = (str) => {
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
            const char = str.charCodeAt(i);
            hash = ((hash << 5) - hash) + char;
            hash = hash & hash;
        }
        return Math.abs(hash);
    };

    const spineColor = useMemo(() => {
        const hash = hashCode(id + title);
        return availableColors[hash % availableColors.length];
    }, [id, title]);

    const getDarkerColor = (hexColor) => {
        const darkenAmount = 0.3;
        const rgb = hexColor.match(/\w\w/g).map(x => parseInt(x, 16));
        const darker = rgb.map(x => Math.max(0, Math.floor(x * (1 - darkenAmount))));
        return `#${darker.map(x => x.toString(16).padStart(2, '0')).join('')}`;
    };

    const outlineColor = useMemo(() => getDarkerColor(spineColor), [spineColor]);

    const getContrastColor = (hexColor) => {
        const r = parseInt(hexColor.slice(1, 3), 16);
        const g = parseInt(hexColor.slice(3, 5), 16);
        const b = parseInt(hexColor.slice(5, 7), 16);
        const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
        return (yiq >= 128) ? '#000000' : '#FFFFFF';
    };

    const spineTextColor = useMemo(() => getContrastColor(spineColor), [spineColor]);
    const coverTextColor = useMemo(() => getContrastColor(spineColor), [spineColor]);

    const handleClick = (e) => {
        e.preventDefault();
        if (isMobile) {
            if (isTapped) {
                if (link) window.open(link, '_blank');
                setIsTapped(false);
            } else {
                setIsTapped(true);
            }
        } else {
            if (link) window.open(link, '_blank');
        }
    };

    const spineStyle = {
        position: 'absolute',
        width: `${width}px`,
        height: '100%',
        backgroundColor: spineColor,
        backgroundImage: `
            linear-gradient(90deg, 
                rgba(0,0,0,0.2) 0%, 
                rgba(0,0,0,0.1) 20%, 
                rgba(0,0,0,0.1) 80%, 
                rgba(0,0,0,0.2) 100%)
        `,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '12px 4px',
        color: spineTextColor,  // Use dynamic spine text color
        overflow: 'hidden',
        transformOrigin: 'left',
        transform: 'rotateY(0deg)',
        boxSizing: 'border-box',
        border: `2px solid ${outlineColor}`,
        boxShadow: 'inset 0 0 10px rgba(0,0,0,0.3)',
        textShadow: spineTextColor === '#FFFFFF' ? '1px 1px 2px rgba(0,0,0,0.5)' : 'none',
    };

    const coverWidth = Math.min(200, width * 3);
    const coverStyle = {
        position: 'absolute',
        width: `${coverWidth}px`,
        height: '100%',
        left: `${width}px`,
        backgroundColor: coverImage ? 'transparent' : spineColor,
        backgroundImage: coverImage ? `url(${coverImage})` : 'none',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        transformOrigin: 'left',
        transform: 'rotateY(90deg)',
        boxSizing: 'border-box',
        border: `2px solid ${outlineColor}`,
        boxShadow: 'inset 5px 0 10px rgba(0,0,0,0.2)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: '10px',
        fontSize: '12px',
        textAlign: 'center',
        color: coverImage ? '#FFFFFF' : coverTextColor,
        overflow: 'hidden',
    };

    const coverOverlayStyle = coverImage ? {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0,0,0,0.6)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: '10px',
        cursor: 'pointer',
    } : {};

    const topStyle = {
        position: 'absolute',
        width: `${width}px`,
        height: `${coverWidth}px`,
        top: `-${coverWidth}px`,
        backgroundColor: spineColor,
        backgroundImage: coverImage ? `url(${coverImage})` : 'none',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        transformOrigin: 'bottom',
        transform: 'rotateX(90deg)',
        boxSizing: 'border-box',
        border: `2px solid ${outlineColor}`,
        boxShadow: 'inset 0 5px 10px rgba(0,0,0,0.2)',
    };

    const titleFontSize = Math.min(18, Math.max(14, width / 4));
    const titleStyle = {
        writingMode: 'vertical-rl',
        textOrientation: 'mixed',
        transform: 'rotate(180deg)',
        width: `${width - 8}px`,
        fontSize: `${titleFontSize}px`,
        fontWeight: 'bold',
        lineHeight: 1.2,
        letterSpacing: '0.5px',
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        maxHeight: `${height - 80}px`,
        overflow: 'hidden',
        margin: 'auto',
        padding: '4px',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%) rotate(180deg)',
    };

    const iconStyle = {
        fontSize: '24px',
        marginBottom: '8px',
    };

    const metaStyle = {
        fontSize: '10px',
        textAlign: 'center',
        marginTop: '8px',
        position: 'absolute',
        bottom: '12px',
        left: '50%',
        transform: 'translateX(-50%)',
        width: '100%',
    };

    return (
        <div className="book-wrapper" style={{
            width: `${width}px`,
            height: `${height}px`,
            position: 'relative',
            transformStyle: 'preserve-3d',
            transition: 'transform 0.5s, width 0.3s',
            transform: `rotateZ(${tiltAngle}deg)`,
            cursor: 'pointer',
        }}>
            <a href={link} onClick={handleClick} style={{
                textDecoration: 'none',
                color: 'inherit',
                display: 'block',
                width: '100%',
                height: '100%',
            }}>
                <div className="book" style={{
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                    transformStyle: 'preserve-3d',
                    transition: 'transform 0.5s, box-shadow 0.5s',
                }}>
                    <div className="spine" style={spineStyle}>
                        {icon && <div style={iconStyle}>{icon}</div>}
                        <div style={titleStyle}>
                            <span>{title}</span>
                        </div>
                        <div style={metaStyle}>
                            <div>{created}</div>
                        </div>
                    </div>
                    <div className="cover" style={coverStyle}>
                        {coverImage ? (
                            <div style={coverOverlayStyle}>
                                <div>
                                    {icon && <div style={{ fontSize: '28px', marginBottom: '12px' }}>{icon}</div>}
                                    <div style={{ fontWeight: 'bold', marginBottom: '12px', fontSize: '18px' }}>{title}</div>
                                    <div style={{ fontSize: '14px', marginBottom: '8px' }}>{created}</div>
                                </div>
                                <div>{tags.join(' / ')}</div>
                            </div>
                        ) : (
                            <>
                                <div>
                                    {icon && <div style={{ fontSize: '28px', marginBottom: '12px' }}>{icon}</div>}
                                    <div style={{ fontWeight: 'bold', marginBottom: '12px', fontSize: '18px' }}>{title}</div>
                                    <div style={{ fontSize: '14px', marginBottom: '8px' }}>{created}</div>
                                </div>
                                <div>{tags.join(' / ')}</div>
                            </>
                        )}
                    </div>
                    <div className="top" style={topStyle}></div>
                </div>
            </a>
        </div>
    );
};

export default Book;