import { API_BASE_URL, API_ENDPOINTS } from '../config';

export const fetchBooksFromNotion = async () => {
    try {
        const response = await fetch(`${API_BASE_URL}${API_ENDPOINTS.BOOKS}`);

        if (!response.ok) {
            throw new Error('Failed to fetch data from server');
        }

        return await response.json();
    } catch (error) {
        console.error("Error fetching books from server:", error);
        throw error;
    }
};