import React from 'react';
import Bookcase from './components/Bookcase';

function App() {
    return (
        <div className="App">
            <Bookcase />
        </div>
    );
}

export default App;